import styled from 'styled-components';

export const CTA = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 1.5rem;
`;

export const Icon = styled.img`
    display: inline;
    vertical-align: middle;

    & + * {
        margin-left: 10px;
    }
`;

export const Important = styled.p`
    max-width: 460px;
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    color: #000000;
`;

export const QRArea = styled.div`
    width: 100%;
    margin: 0;

    & > * {
        display: block;
        margin: 1.5rem auto !important;
    }
`;

export const Title = styled.h2`
    text-align: center;
    text-transform: uppercase;
    font-size: 22px;
    font-weight: 700;
    color: var(--green);
    margin-bottom: 1rem;
`;

export const TopBar = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column  ;
    justify-content: space-around;
    align-items: center;
    font-size: 12px;

    & > * + * {
        margin-top: 1rem
    }

    @media (min-width: 1024px) {
        flex-direction: row;

        & > * + * {
            margin-top: 0;
        }
    }

    p {
        display: inline-block;
        vertical-align: middle;
        
        & + * {
            margin-left: 10px;
        }
    }
`;

export const Return = styled.p`
    margin-top: 2rem;
    text-align: center;
`;

export const StatusContainer = styled.div`
    text-align: center;

    & > img {
        margin-bottom: 1rem;
    }
`;

export const Warning = styled.p`
    text-align: center;
    color: #4D4D4D;
    margin: 2rem 0 0.5rem;

    strong {
        text-transform: uppercase;
    }
`;
