export const checkObject = (obj: any, objPathString: string, firstPass?: boolean) => {
    let objPathArray = objPathString.split(".");

    if (firstPass) {
        if (!objPathString) {
            console.error("String com o caminho do objeto está vazia!");
            return false;
        }

        objPathArray.shift();
    }

    let objNextPath = objPathArray.shift();

    if (!!obj) {
        if (!!objNextPath) {
            checkObject(obj[objNextPath], objPathArray.join("."));
        }

        return true;
    }

    return false;
}

export const checkMultObjects = (obj: any, objPaths: string[]) => {
    let isMissing = true;

    if (!!objPaths) {
        for (let i = 0; i < objPaths.length; i++) {
            const index = i;
            if (!checkObject(obj, objPaths[index], true)) {
                isMissing = false;
                break;
            }
        }
    }

    return isMissing;
}

export const firstLetterCapitalized = (text: string) => {
    let formatedText = "";
    const lowerCaseText = text.toLocaleLowerCase();
    formatedText = lowerCaseText.charAt(0).toUpperCase() + lowerCaseText.slice(1);

    return formatedText;
}

export const formatarCpfCnpj = (data: string) => {
    let formatedData = "";

    if (!!data) {
        if (data.length > 11) {
            formatedData = data.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
        } else {
            formatedData = data.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "***.$2.$3-**");
        }
    }

    return formatedData;
}

export const formatarDataHora = (tipoFormatacao: string, data: string) => {
    let resultado: string = "";
    let ano, mes, dia;
    let hora, minuto;

    let date = new Date(data);

    dia = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    mes = date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1);
    ano = date.getFullYear();
    hora = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    minuto = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();

    switch (tipoFormatacao) {
        case "Data":
            resultado = `${dia}/${mes}/${ano}`;
            break;

        case "Data-Hora":
            resultado = `${dia}/${mes}/${ano} - ${hora}:${minuto}`;
            break;

        case "Hora":
            resultado = `${hora}:${minuto}`;
            break;

        default:
            console.error("tipoFormatacao inválido!");
            break;
    }

    return resultado;
}

export const formatarMoeda = (value: number) => {
    const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    });

    return formatter.format(value);
}

export const formatarNumConta = (value: string) => {
    let numContaFormatado = "";
    const regex = /^(\d+)(\d{1})$/g;
    const result = regex.exec(value);

    if (result) {
        numContaFormatado = `${result[1]}-${result[2]}`;
    } else {
        numContaFormatado = value;
    }

    return numContaFormatado;
}


export const formatarTipoConta = (tipoConta: string) => {
    let tipoContaFormatada;

    if (tipoConta === "CACC") {
        tipoContaFormatada = "Conta Corrente";
    } else {
        tipoContaFormatada = tipoConta;
    }

    return tipoContaFormatada;
}

export const getUrlParam = (param: string) => {
    const url: Location = window.location;
    let result: string|null = null;
    let urlParams: Array<string>;

    if (url.search) {
        if (param === "redirect") {
            const regex = /redirect=([a-z0-9./\-?=&]+)&state/gi;
            const regexResult = regex.exec(url.search);

            if (regexResult && regexResult[1]) {
                result = regexResult[1];
            }

        } else {
            urlParams = url.search.replace("?","").split("&");

            for (let i = 0; i < urlParams.length; i++) {
                let currentParam = urlParams[i].split("=");

                if (param === currentParam[0]) {
                    result = currentParam[1];
                    break;
                }
            }
        }

    }

    if (!result) {
        console.info(`O parâmetro '${param}' não existe na url!`);
    }

    return result;
};

export default {
    checkObject,
    getUrlParam,
    formatarTipoConta,
    formatarNumConta,
    formatarMoeda,
    formatarDataHora,
    formatarCpfCnpj,
    firstLetterCapitalized
};
