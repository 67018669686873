import styled from 'styled-components';

export const Logos = styled.div`
    width: 100%;
    margin: 3rem 0;

    @media (min-width: 1024px) {
        div {
            text-align: center;
        }
    }
`;

export const OpenBankingLogo = styled.img`
    height: 36px;
`;

export const UnicredLogo = styled.img`
    height: 23px;
    margin-bottom: 0.5rem;
`;