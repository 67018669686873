import { useContext, useEffect, useState } from "react";
import { CompartilhamentoContext } from "../../../context/CompartilhamentoContext";
import { ModalContext } from "../../../context/ModalContext";
import { RequestedDataArea } from "./styles";
import iconAccount from "../../../assets/icons/share/account-icon.svg";
import iconCard from "../../../assets/icons/share/credit-card-icon.svg";
import iconGeneric from "../../../assets/icons/share/generic-icon.svg";
import iconInvestments from "../../../assets/icons/share/investments-icon.svg";
import iconOperation from "../../../assets/icons/share/operation-icon.svg";
import iconUser from "../../../assets/icons/share/user-icon.svg";
import iconExchanges from "../../../assets/icons/share/exchanges-icon.svg";
import { checkObject } from "../../../common/Helpers";

const RequestedData = (): JSX.Element => {
    const shareContext = useContext(CompartilhamentoContext);
    const modalContext = useContext(ModalContext);
    const [catReqData, setCatReqData] = useState([""]);
    const [hasRequestedData, setHasRequestedData] = useState(false);

    const openModal = (title: string) => {

        shareContext.infoModalName = title;

        modalContext.update("", {
            title,
            text: "",
            confirmBtn: "Fechar",
            cancelBtn: "",
            type: "INFO-SHARE",
            showModal: true
        });
    }

    useEffect(() => {
        const checkedDadosSolicitados = checkObject(shareContext, "shareContext.dados.dadosCompartilhamento.dadosSolicitados", true);

        if (checkedDadosSolicitados) {
            // Alphabetical order
            const dadosSolicitados = shareContext.dados.dadosCompartilhamento.dadosSolicitados.sort((a,b) => {
                return a.nome < b.nome ? -1 : a.nome > b.nome ? 1 : 0;
            });
                    
            for (let i = 0; i < dadosSolicitados.length; i++) {
                if (!catReqData.includes(dadosSolicitados[i].nomeCategoria)) {
                    setCatReqData(oldList => [...oldList, dadosSolicitados[i].nomeCategoria]);
                }
            }

            setHasRequestedData(true);
        } else {
            console.error("Nenhum dado solicitado foi encontrado!");
        }
    }, []);

    return (
        <div>
            <h3>Dados solicitados</h3>

            {hasRequestedData ?
                <RequestedDataArea>
                    {shareContext.dados.dadosCompartilhamento.dadosSolicitados.map((item, key) => (
                        <li key={key}>
                            {item.nomeCategoria === "Dados cadastrais" ? 
                                <img src={iconUser} alt="ícone"></img>
                            : item.nomeCategoria === "Dados da conta" ?
                                <img src={iconAccount} alt="ícone"></img>
                            : item.nomeCategoria === "Dados de cartões de crédito" ?
                                <img src={iconCard} alt="ícone"></img>
                            : item.nomeCategoria === "Dados de operações de crédito" ?
                                <img src={iconOperation} alt="ícone"></img>
                            : item.nomeCategoria === "Dados de investimentos" ? 
                                <img src={iconInvestments} alt="ícone"></img>
                            : item.nomeCategoria === "Operações de câmbio" ? 
                                <img src={iconExchanges} alt="ícone"></img>
                            :
                                <img src={iconGeneric} alt="ícone"></img>
                            }
                            <h4>{item.nome}</h4>
                            <button data-testid="button_generic" onClick={() => {openModal(item.nome)}}>
                                Visualizar &gt;
                            </button>
                        </li>
                    ))}
                </RequestedDataArea>
            :
                <span className="transaction-info">Carregando...</span>
            }
        </div>
    );
}

export default RequestedData;