import styled from 'styled-components';
import { darken } from 'polished';

export const Content = styled.button`
    min-width: 230px;
    max-width: 370px;
    border: 1px solid #FFFFFF;
    padding: 1rem 2rem;
    background: var(--green);
    border-radius: 0.625rem;
    color: #FFFFFF;
    font-weight: 700;
    transition: background-color 0.15s;

    &.full {
        width: 100%;
    }

    &:hover {
        background-color: ${darken(0.05, '#166649')};
    }

    &.secondary {
        background: var(--gray-light);
        border: 1px solid var(--green);
        color: var(--green);

        &.alt {
            border: 1px solid transparent;
        }
    }

    &.secondary:hover {
        background-color: ${darken(0.05, '#F2F2F2')};
    }

    &:disabled {
        background: #D9D9D9;
        color: #A6A6A6;
        opacity: 1;
    }

    & + button {
        margin-top: 1rem;
    }
`;