import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { consentId } from '../../../services/api';
import { CompartilhamentoContext } from '../../../context/CompartilhamentoContext';
import { ModalContext } from '../../../context/ModalContext';
import { checkMultObjects, formatarCpfCnpj, formatarDataHora } from '../../../common/Helpers';
import { Container, SubContainer } from '../../../components/Container';
import { Button } from '../../../components/Button';
import { CTA, Description, Transaction } from '../styles';
import { BusinessEntityArea, CardBottomExplanation } from './styles';
import { Card } from '../../../components/Card';
import Title from '../../../components/Title';
import RequestedData from './RequestedData';


const Share = (): JSX.Element => {
    const history = useNavigate();
    const modal = useContext(ModalContext);
    let context = useContext(CompartilhamentoContext);
    const [multiConta, setMultiConta] = useState(false);
    const [mostrarAside, setMostrarAside] = useState(false);
    const [cpfCnpj, setCpfCnpj] = useState("Carregando...");
    const [cnpj, setCnpj] = useState("");
    const [data, setData] = useState("Carregando...");
    const [dataExpiracao, setDataExpiracao] = useState("Carregando...");
    const [expiraCompartilhamento, setExpiraCompartilhamento] = useState<string | null>("Carregando...");
    const [horaSolicitacao, setHoraSolicitacao] = useState("00:00");
    const [logoInstituicao, setLogoInstituicao] = useState("");
    const [nome, setNome] = useState("");
    const [nomeInstituicao, setNomeInstituicao] = useState("Carregando...");
    const [prazoCompartilhamento, setPrazoCompartilhamento] = useState("Carregando...");

    const cancel = () => {
        modal.update("CANCEL-SHARE");
    };

    const confirm = () => {
        let produtos = [{
            modalidades: [{
                codigo: 0,
                descricao: ""
            }],
            nome: ""
        }];

        for (let i = 0; i < context.creditModalitites.data.length; i++) {
            const produto = context.creditModalitites.data[i];
            let modalidades = [];

            if (produto.numSelected > 0) {

                for (let j = 0; j < produto.modalidade.length; j++) {
                    const modalidade = produto.modalidade[j];

                    if (modalidade.selected) {
                        modalidades.push({
                            codigo: modalidade.codigo,
                            descricao: modalidade.descricao
                        });
                    }
                }

                produtos.push({
                    modalidades,
                    nome: produto.nome
                });
            }
        }

        produtos.shift();

        context.payload = {
            ...context.payload,
            compartilhamentoId: context.dados.id,
            consentimentoId: consentId,
            produtos
        }

        history("/segredo");
    };

    const atualizaDados = () => {
        const dados = context.dados;
        const duplaAssinatura = dados.duplaAssinatura;

        const neededValues: string[] = [
            "dados.quantidadeUsuariosConta",
            "dados.dataExpiracao",
            "dados.cpfCnpj",
            "dados.instituicao.cnpj",
            "dados.dadosCompartilhamento.data",
            "dados.dadosCompartilhamento.prazo",
            "dados.instituicao.icone",
            "dados.nome",
            "dados.instituicao.nome",
            "dados.dadosCompartilhamento.dataExpiracaoCompartilhamento"
        ];

        // Check if object has all values before setting them
        if (checkMultObjects(dados, neededValues)) {

            if (duplaAssinatura || dados.quantidadeUsuariosConta > 1) {
                setMostrarAside(true);

                const dateCriacao = new Date(dados.dataExpiracao);
                const horaFormatada = dateCriacao.getHours() < 9 ? "0" + dateCriacao.getHours() : dateCriacao.getHours();
                const minutosFormatados = dateCriacao.getMinutes() < 10 ? "0" + dateCriacao.getMinutes() : dateCriacao.getMinutes();
                setHoraSolicitacao(`${horaFormatada}:${minutosFormatados}`);
            }

            setMultiConta(duplaAssinatura);
            setCpfCnpj(formatarCpfCnpj(dados.cpfCnpj));
            setCnpj(formatarCpfCnpj(dados.instituicao.cnpj));
            setData(formatarDataHora("Data", dados.dadosCompartilhamento.data));
            setDataExpiracao(formatarDataHora("Data", dados.dataExpiracao));
            setLogoInstituicao(dados.instituicao.icone);
            setNome(dados.nome);
            setNomeInstituicao(dados.instituicao.nome);
            setPrazoCompartilhamento(dados.dadosCompartilhamento.prazo);

            if (dados.dadosCompartilhamento.dataExpiracaoCompartilhamento) {
                setExpiraCompartilhamento(formatarDataHora("Data", dados.dadosCompartilhamento.dataExpiracaoCompartilhamento));
            } else {
                setExpiraCompartilhamento(null);
            }
        }
    };

    useEffect(() => {
        if (!context.hasData) {
            history("/");
        }

        atualizaDados();
    }, [history]);

    return (
        <Container>
            <SubContainer>
                <Title title="Confirmação">
                    <p>
                        Revise os dados abaixo para continuar com o compartilhamento de dados.< br />
                        Você possui até uma hora para confirmá-la.
                    </p>
                </Title>

                <Transaction>
                    <div>
                        <Card style={{ minHeight: multiConta ? "385px" : "" }}>
                            <div className="card-wrapper">
                                <div className="card-body-line">
                                    <div>
                                        <h3>Identificação</h3>
                                        <span className="transaction-info">{nome}</span>
                                        <span className="transaction-info">{`${cpfCnpj.length > 14 ? "CNPJ" : "CPF"} ${cpfCnpj}`}</span>
                                    </div>

                                    <div>
                                        <h3>Instituição de destino</h3>
                                        <BusinessEntityArea>
                                            <img src={logoInstituicao} alt="Logo da instituição" className={logoInstituicao !== "" ? "" : "hide"}></img>
                                            <div>
                                                <span className="transaction-info">{nomeInstituicao}</span>
                                                <span className="transaction-info">CNPJ {cnpj}</span>
                                            </div>
                                        </BusinessEntityArea>
                                    </div>

                                    <div>
                                        <h3>Data da solicitação</h3>
                                        <span className="transaction-info">{data}</span>
                                    </div>

                                    <div>
                                        <h3>Prazo de compartilhamento</h3>
                                        <span className="transaction-info">
                                            {prazoCompartilhamento}
                                            {expiraCompartilhamento && <> | {expiraCompartilhamento}</>}
                                        </span>
                                    </div>

                                    <RequestedData />

                                    {
                                        // Removed on version 2.1.1
                                        // <CreditModalities />
                                    }
                                    {
                                        !!context.dados.textoOperacaoCreditoInvestimento && (
                                            <div>
                                                <CardBottomExplanation>
                                                    {context.dados.textoOperacaoCreditoInvestimento}
                                                </CardBottomExplanation>
                                            </div>

                                        )
                                    }

                                </div>
                            </div>
                        </Card>
                    </div>

                    <div className={mostrarAside ? "" : "hide"}>
                        <Description>
                            <h2>Autorização</h2>
                            <p>
                                Identificamos que será necessária uma ou mais autorizações para
                                concluir este compartilhamento.
                            </p>
                        </Description>

                        <Card>
                            <div className="card-wrapper">
                                <div className='card-header'>
                                    <h2>Importante</h2>
                                    {multiConta ?
                                        <p>
                                            O compartilhamento de dados só será efetivado após a
                                            autorização de todos os aprovadores.
                                            <br /><br />
                                            Ao realizar a autorização, alguns dados em comum com
                                            demais usuários da conta escolhida podem ser compartilhados.
                                        </p>
                                        :
                                        <p>
                                            Ao realizar a autorização, alguns dados em comum com demais
                                            usuários da conta escolhida podem ser compartilhados.
                                        </p>
                                    }

                                </div>
                                <div className='card-body center'>
                                    {multiConta ?
                                        <p>Os demais aprovadores têm até:</p>
                                        :
                                        <p>Você têm até:</p>
                                    }

                                    <div className="authorization-limit">
                                        <span>{dataExpiracao}</span>
                                        &nbsp;às&nbsp;
                                        <span>{horaSolicitacao}</span>
                                    </div>

                                    <p>
                                        para autorizar o compartilhamento de dados nos canais da Unicred.
                                        Após esse prazo deverá ser feita uma nova solicitação de
                                        compartilhamento.
                                    </p>
                                </div>
                            </div>
                        </Card>
                    </div>
                </Transaction>

                <CTA>
                    <Button className="full" value="Confirmar compartilhamento" onClick={confirm} />
                    <Button className="secondary full" value="Recusar compartilhamento" onClick={cancel} />
                </CTA>
            </SubContainer>
        </Container>
    );
};

export default Share;