import styled from "styled-components";

export const CallbackWrapper = styled.main`
    display: flex;
    flex-direction: column;
    align-items: center;

    & p {
        max-width: 400px;
        margin: 0 auto;

    }

    & button:first-of-type {
        margin-top: 13rem;
    }
`;