import { ReactChild } from 'react';
import { CSSProperties } from 'styled-components';
import { Content } from './styles'

type Props = {
    className?: string;
    children?: ReactChild;
    style?: CSSProperties;
};

export function Card({className, children, style }: Props) {
    return (
        <Content className={className} style={style}>
            {children}
        </Content>
    )
}