import { Content, Icon } from './styles'

import line1 from '../../assets/images/background-lines/top-left.svg'
import line2 from '../../assets/images/background-lines/top-right.svg'
import line3 from '../../assets/images/background-lines/bottom-right.svg'

export function BackLines() {
    return (
        <Content id="background-lines">
            <Icon src={line1} id="bg-line-1" alt="Linha decorativa 1" />
            <Icon src={line2} id="bg-line-2" alt="Linha decorativa 2" />
            <Icon src={line3} id="bg-line-3" alt="Linha decorativa 3" />
        </Content>
    )
}