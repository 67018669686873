import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Validator from '../../common/Validator';
import { CompartilhamentoContext } from '../../context/CompartilhamentoContext';
import { ConsentimentoRequest } from '../../context/ConsentimentoContext';
import { ModalContext } from '../../context/ModalContext';
import Information from '../../pages/Description/Share/Information.modal';
import SelectInfo from '../../pages/Description/Share/SelectInfo.modal';
import { Content } from './styles';
import { Button } from '../Button';


export function Modal () {
    const history = useNavigate();
    const context = useContext(ModalContext);
    const contextData = context.data;
    const contextConsentimento = useContext(ConsentimentoRequest);
    const contextCompartilhamento = useContext(CompartilhamentoContext);
    const [show, setShow] = useState(false)

    const closeModal = () => {
        context.update("", {...contextData, showModal: false});
    };

    const cancelConsent = () => {
        closeModal();

        Validator.cancel(history, contextConsentimento, (response: any) => {
            contextConsentimento.iniciadora = response;
            history("/redirect");
        });
    };

    const rejectShare = () => {
        closeModal();
        Validator.cancelShare(history, contextCompartilhamento.dados.id, (response: any) => {
            contextConsentimento.iniciadora.urlRedirecionamento = response.urlRedirect;
            history("/redirect");
        });
    };

    const confirmOverdraft = async () => {
        contextConsentimento.request.usarChequeEspecial = true;

        Validator.authorize(context, history, contextConsentimento, (response: any) => {
            contextConsentimento.iniciadora = response;
            history("/redirect");
        },
        (errorMessage: string) => {
            console.error(errorMessage);
            history("/responses");
        });

        closeModal();
    }

    useEffect(() => {
        setShow(contextData.showModal);
    }, [context]);

    return (
        <Content  id="modal" className={show ? "" : "hide"}>
            <div data-testid="modal_teste" className="modal-wrapper">
                <div className="modal-header">                        
                    <h2>{contextData.title}</h2>
                </div>

                <div className="modal-content">
                    {contextData.type === "INFO-SHARE" ?
                        <Information title={contextCompartilhamento.infoModalName} />
                    : contextData.type === "SELECT-SHARE" ?
                        <SelectInfo product={contextCompartilhamento.selectModalProduct} />
                    : 
                        <p>{contextData.text}</p>
                    }
                </div>

                {contextData.type === "CANCEL" ?
                    <div data-testid ="modal_cancel" className="modal-footer dialog">
                        <Button data-testid="button_close_cancel" onClick={closeModal} value={contextData.cancelBtn} className="secondary"/>
                        <Button data-testid="button_confirm_cancel" onClick={cancelConsent} value={contextData.confirmBtn}/>
                    </div>
                : contextData.type === "CANCEL-SHARE" ?
                    <div className="modal-footer dialog">
                        <Button onClick={closeModal} value={contextData.cancelBtn} className="secondary"/>
                        <Button data-testid="button_confirm_cancel_share" onClick={rejectShare} value={contextData.confirmBtn}/>
                    </div>    
                : contextData.type === "FUNDS" ?
                    <div data-testid ="modal_funds" className="modal-footer">
                        <button data-testid="button_confirm_funds" onClick={closeModal} className='button-close'>{contextData.confirmBtn}</button>
                    </div>
                : contextData.type === "OVERDRAFT" ?
                    <div data-testid ="modal_overdraft" className="modal-footer dialog">
                        <Button onClick={closeModal} value={contextData.cancelBtn} className="secondary"/>
                        <Button data-testid="button_confirm_Overdraft" onClick={confirmOverdraft} value={contextData.confirmBtn} />
                    </div>
                :
                    <div className="modal-footer">
                        <button data-testid="close_modal" onClick={closeModal} className='button-close'>{contextData.confirmBtn}</button>
                    </div>
                }
            </div>
        </Content>
    );
}
