import { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ConsentimentoRequest } from '../../context/ConsentimentoContext';
import { CompartilhamentoContext } from '../../context/CompartilhamentoContext';
import { Container, SubContainer } from '../../components/Container';
import { Button } from '../../components/Button';
import Title from '../../components/Title';
import { MessagesWrap } from './styles';
import alertIcon from '../../assets/icons/ubr-alert.svg';
import clockIcon from '../../assets/icons/ubr-clock.svg';
import errorIcon from '../../assets/icons/ubr-error.svg';
import expiredIcon from '../../assets/icons/ubr-expired.svg';
import { AuthContext } from '../../context/AuthContext';
import Validator from '../../common/Validator';


interface Params {
  type?: string;
}

interface IconsPathObject {
  [key: string]: string;
}

const Messages = (): JSX.Element => {
  const params: Params = useParams();
  const contextConsentimento = useContext(ConsentimentoRequest);
  const { isShare } = useContext(CompartilhamentoContext);
  const [icon, setIcon] = useState('');
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [isCpf, setIsCpf] = useState(false);
  const [toggleDisabled, setToggleDisabled] = useState(false);
  const history = useNavigate();

  const authContext = useContext(AuthContext);

  const iconsPath: IconsPathObject = {
    alert: alertIcon,
    error: errorIcon,
    expired: expiredIcon,
    processing: clockIcon,
  };

  const redirectUnicred = () => {
    authContext.logout();
    window.location.href = 'https://unicred.com.br/home';
  };

  const logout = () => {
    authContext.logout();
  };

  const rejectAndRedirect = () => {
    Validator.reject(history, (response: any) => {
      contextConsentimento.iniciadora = response;
      contextConsentimento.hasData = true;
      history("/redirect");
    });
  };

  const updateContent = () => {
    let iconPath = iconsPath.error;
    let newTitle = 'Algo deu errado';
    let newText =
      'Infelizmente, ocorreu um erro na operação. Por favor, reinicie a transação na instituição iniciadora de pagamentos.';

    if (isShare) {
      newText =
        'Infelizmente, ocorreu um erro na operação. Por favor, solicite o compartilhamento novamente.';
    }

    if (params && params.type) {
      if (params.type === 'alerta') {
        iconPath = iconsPath.alert;
        newTitle = 'Não é possível continuar';
        if (contextConsentimento.errorMessage) {
          newText = contextConsentimento.errorMessage;
          if (newText.search(/cpf/gi) > -1) {
            setIsCpf(true);
          }

          if (newText.search(/instabilidade/gi) > -1) {
            setToggleDisabled(true);
            newTitle = 'Algo deu errado';
          }
        }
      } else if (params.type === 'cancelado') {
        newTitle = 'Transação cancelada';
        newText = 'Você cancelou a transação com sucesso.';
      } else if (params.type === 'compartilhamento-recusado') {
        newTitle = 'Compartilhamento recusado';
        newText = 'Você recusou o compartilhamento com sucesso.';
      } else if (params.type === 'expirado') {
        iconPath = iconsPath.expired;
        newTitle = 'Tempo expirado';
        if (isShare) {
          newText =
            'O tempo de 60 minutos para confirmar o compartilhamento expirou e a operação não pode ser realizada.\nPor favor, solicite um novo compartilhamento na instituição de destino dos dados.';
        } else {
          newText =
            'O tempo de 5 minutos para confirmar a transação expirou e a operação não será realizada. Por favor, reinicie a transação na instituição iniciadora de pagamentos.';
        }
      } else if (params.type === 'processando') {
        iconPath = iconsPath.processing;
        if (isShare) {
          newTitle = 'O compartilhamento está sendo processado';
          newText =
            'A confirmação da operação está em processamento e pode demorar alguns minutos para ser contabilizada. Verifique se a transação foi efetivada no extrato da sua conta. Caso contrário, reinicie a operação na instituição iniciadora de pagamentos.';
        } else {
          newTitle = 'A transação está sendo processada';
          newText =
            'A confirmação da operação está em processamento e pode demorar alguns minutos para ser contabilizada. Verifique se a transação foi efetivada no extrato da sua conta. Caso contrário, reinicie a operação na instituição iniciadora de pagamentos.';
        }
      } else if (params.type === 'redirect') {
        if (isShare) {
          newText =
            'Infelizmente, ocorreu um erro na operação. Verifique se o compartilhamento foi autorizado na instituição de destino ou em sua conta. Caso contrário, solicite o compartilhamento novamente.';
        } else {
          newText =
            'Infelizmente, ocorreu um erro na operação. Verifique se a transação foi efetivada no extrato da sua conta. Caso contrário, reinicie a transação na instituição iniciadora de pagamentos.';
        }
      }
    }

    setIcon(iconPath);
    setTitle(newTitle);
    setText(newText);
  };

  useEffect(() => {
    updateContent();
  }, [contextConsentimento]);

  return (
    <Container>
      <SubContainer>
        <MessagesWrap>
          <Title title={title} icon={icon}>
            <p>{text}</p>
          </Title>

          {isCpf ? (
            <>
              <Button className={`full`} value="Acessar outra conta" onClick={logout} />
              <Button
                className={`secondary full`}
                style={{ border: 'none' }}
                value="Sair"
                onClick={redirectUnicred}
              />
            </>
          ) : (
            <Button
              className={`secondary full`}
              value={toggleDisabled ? "Voltar para a outra instituição" : "Ir para o site da Unicred"}
              onClick={toggleDisabled ? rejectAndRedirect : redirectUnicred}
            />
          )}
        </MessagesWrap>
      </SubContainer>
    </Container>
  );
};

export default Messages;
