import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import QRCodeGenerator from 'react-qr-code';
import { SubContainer } from '../../../components/Container';
import { CTA, Icon, Important, QRArea, Return, StatusContainer, Title, TopBar, Warning } from './styles';
import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { ConsentimentoRequest } from '../../../context/ConsentimentoContext';
import { CompartilhamentoContext } from '../../../context/CompartilhamentoContext';
import { seguranca } from '../../../services/seguranca.service';
import { ModalContext } from '../../../context/ModalContext';
import Validator from '../../../common/Validator';
import cellphoneIcon from '../../../assets/icons/ubr-cellphone.svg';
import qrcodeIcon from '../../../assets/icons/ubr-qrcode.svg';
import clockIcon from '../../../assets/icons/ubr-cloud-clock.svg';
import errorIcon from '../../../assets/icons/ubr-cloud-error.svg';


const QRCode = (): JSX.Element => {
    const history = useNavigate();
    let contextConsentimento = useContext(ConsentimentoRequest);
    let contextCompartilhamento = useContext(CompartilhamentoContext);
    let modal = useContext(ModalContext);
    const [tokenQR, setTokenQR] = useState("");
    const [uuid, setUuid] = useState("");
    const [status, setStatus] = useState(1);
    const [dadosQR, setDadosQR] = useState({
        transacao: {
            codTransacao: 0,
            data: "",
            descricao: "",
            valor: 0
        },
        hash: ""
    });

    const approved = () => {
        if (contextCompartilhamento.isShare) {
            Validator.authorizeShare(history, contextCompartilhamento, (response: any) => {
                contextConsentimento.iniciadora.urlRedirecionamento = response.urlRedirect;
                history("/redirect");
            },
                (errorMessage: string) => {
                    contextConsentimento.errorMessage = errorMessage;
                    history("/responses/alerta");
                });
        } else {
            Validator.authorize(modal, history, contextConsentimento, (response: any) => {
                contextConsentimento.iniciadora = response;
                history("/redirect");
            },
                (errorMessage: string) => {
                    contextConsentimento.errorMessage = errorMessage;
                    history("/responses/alerta");
                });
        }
    };

    const cancel = () => {
        if (contextCompartilhamento.isShare) {
            modal.update("CANCEL-SHARE");
        } else {
            modal.update("CANCEL");
        }
    };

    const geraQR = async () => {
        if (!!uuid && !!dadosQR.transacao.data) {
            let promise = seguranca.gerarQRCode(uuid, dadosQR);

            promise.then(response => {
                if (response.data.status === "PENDENTE") {
                    setTokenQR(dadosQR.hash + "|" + response.data.challenge);
                    
                    if (!tokenQR) {
                        setTokenQR(response.data.hash + "|" + response.data.challenge);

                        let newDadosQR = {
                            ...dadosQR,
                            hash: response.data.hash,
                        }

                        setDadosQR(() => newDadosQR);
                    } else {
                        setTimeout(() => {
                            try {
                                geraQR();
                            } catch (error) {
                                console.error(error);
                                setStatus(3);
                            }
                        }, 10000);
                    }
                } else if (response.data.status === "AUTORIZADO") {
                    const hash = response.data.hash

                    if (contextCompartilhamento.isShare) {
                        contextCompartilhamento.payload.unitoken = hash;
                    } else {
                        contextConsentimento.request.unitoken = hash;
                    }

                    approved();
                } else {
                    setStatus(3);
                }
            })
                .catch(error => {
                    console.error(error);
                });

            return;
        }

        setTimeout(() => {
            try {
                geraQR();
            } catch (error) {
                console.error(error);
            }
        }, 10000);
    };

    useEffect(() => {
        if (!!dadosQR.transacao.data) {
            geraQR();

            setTimeout(() => {
                try {
                    setStatus(2);
                } catch (error) {
                    console.error(error);
                }
            }, contextCompartilhamento.isShare ? 1800000 : 90000);
        } else {
            let descricao = "";
            let creditor = "";
            let userDoc = "";
            let novosDadosQR = {
                transacao: {
                    codTransacao: 0,
                    data: "",
                    descricao: "",
                    valor: 0
                },
                hash: ""
            };

            if (contextCompartilhamento.isShare) {
                descricao = "Compartilhamento de dados";

                novosDadosQR.transacao.codTransacao = 196;
                novosDadosQR.transacao.data = contextCompartilhamento.dados.dadosCompartilhamento.data;
                novosDadosQR.transacao.descricao = descricao;

                creditor = contextCompartilhamento.dados.instituicao.cnpj;
                userDoc = contextCompartilhamento.dados.cpfCnpj;

            } else {
                descricao = "Transação Open Finance";

                if (contextConsentimento.request.codTransacao === 169) {
                    descricao = "Pix - Open Finance";
                }

                novosDadosQR.transacao.codTransacao = contextConsentimento.request.codTransacao;
                novosDadosQR.transacao.data = contextConsentimento.request.consentimento.creationDateTime;
                novosDadosQR.transacao.descricao = descricao;
                novosDadosQR.transacao.valor = parseFloat(contextConsentimento.request.consentimento.payment.amount);

                creditor = contextConsentimento.request.consentimento.creditor.cpfCnpj;
                userDoc = contextConsentimento.request.consentimento.loggedUser.document.identification;
            }

            setDadosQR(() => novosDadosQR);
            setUuid(`${creditor}-${userDoc}`);
        }
    }, [dadosQR]);

    return (
        <SubContainer>
            <Card className="full">
                <div className="card-wrapper">
                    <div className="card-body">
                        <Title>Unitoken QR Code</Title>
                        <TopBar>
                            <div>
                                <Icon src={cellphoneIcon} alt="Ícone celular" />
                                <p>
                                    1 - Abra o aplicativo Unicred<br />
                                    e acesse a mesma conta<br />
                                    utilizada no Open Finance
                                </p>
                            </div>
                            <div>
                                <p>
                                    2 - Clique na opção Unitoken
                                </p>
                                <Icon src={qrcodeIcon} alt="Ícone celular" />
                            </div>
                            <div>
                                <p>
                                    3 - Escaneie o QRcode abaixo
                                </p>
                            </div>
                        </TopBar>

                        <StatusContainer className={status === 1 ? "" : "hide"}>
                            <QRArea>
                                <QRCodeGenerator value={tokenQR} size={215} />
                            </QRArea>

                            {contextCompartilhamento.isShare ?
                                <Important>
                                    Atenção: conclua o escaneamento em até 24h antes que sua solicitação
                                    seja cancelada automaticamente
                                </Important>
                                :
                                <Important>
                                    Atenção: conclua o escaneamento em até 1:30 antes que sua transação
                                    seja cancelada automaticamente
                                </Important>
                            }
                        </StatusContainer>

                        <StatusContainer className={status === 2 ? "" : "hide"}>
                            <Icon src={clockIcon} className="status" alt="Ícone relógio" />


                            {contextCompartilhamento.isShare ?
                                <Important>
                                    Tempo expirado para validar o compartilhamento
                                </Important>
                                :
                                <Important>
                                    Tempo expirado para validar a transação
                                </Important>
                            }
                        </StatusContainer>

                        <StatusContainer className={status === 3 ? "" : "hide"}>
                            <Icon src={errorIcon} className="status" alt="Ícone erro" />

                            {contextCompartilhamento.isShare ?
                                <Important>
                                    O compartilhamento não pode ser realizada
                                </Important>
                                :
                                <Important>
                                    A operação não pode ser realizada
                                </Important>
                            }
                        </StatusContainer>

                        <Return className={status !== 1 ? "" : "hide"}>
                            Por favor, retorne à instituição iniciadora de pagamentos e repita
                            a operação.
                        </Return>
                    </div>
                </div>
            </Card>

            {contextCompartilhamento.isShare ?
                <Warning>
                    <strong>Importante:</strong> Ao confirmar o compartilhamento, você será
                    redirecionado para a instituição receptora.
                </Warning>
                :
                <Warning>
                    <strong>Importante:</strong> Ao confirmar a transação, você será redirecionado
                    e seu pagamento confirmado.
                </Warning>
            }

            <CTA>
                <Button className="secondary" value="Cancelar" onClick={cancel} />
            </CTA>
        </SubContainer>
    );
};

export default QRCode;