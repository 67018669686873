import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Header } from './../../components/Header';
import { Footer } from './../../components/Footer';
import { BackLines } from '../../components/BackLines';
import { Modal } from '../../components/Modal';
import GlobalStyles from '../../styles/global';

const MainLayout = (): JSX.Element => {
  return (
    <>
      <GlobalStyles />
      <Header />
      <Outlet />
      <Footer />
      <Modal />
      <BackLines />
      <ToastContainer autoClose={3000} />
    </>
  );
};

export default MainLayout;
