import { Content, LockIcon, Message } from './styles'

import lockIcon from '../../assets/icons/ubr-padlock.svg'
import { useContext } from 'react';
import { ModalContext } from '../../context/ModalContext';

export function Footer() {
    let modal = useContext(ModalContext);

    const showModal = () => {
        modal.update("INFO");
    };

    return (
        <Content>
            <Message onClick={showModal}>
                <LockIcon src={lockIcon} alt="Cadeado" />
                <span>Você está em uma transação segura. Clique aqui para saber mais.</span>
            </Message>
        </Content>
    );
}
