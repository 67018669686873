import styled from 'styled-components';

export const LockIcon = styled.img`
    padding-right: 0.25rem;
    width: 1.125rem;
`;

export const Content = styled.footer`
    padding: 4rem 0 1rem;
    color: var(--gray-dark);
    font-size: 0.75rem;
    display: flex;
    justify-content: center;
    transition: filter 0.15s;
`;

export const Message = styled.div`
    cursor: pointer;
    
    & > * {
        vertical-align: middle;
    }
`;

export const Modal = styled.section`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.5);
    z-index: 1000;

    .modal-wrapper {
        width: 330px;
        border-radius: 14px;
        background-color: #FFFFFF;
        text-align: center;
    }

    .modal-content{
        padding: 1rem;
        border-bottom: 1px solid #D9D9D9;

        h2 {
            margin-bottom: 1rem;
            font-size: 16px;
            font-weight: 700;
            color: #333333;
        }

        p {
            font-size: 14px;
            color: #4D4D4D;
        }
    }

    .modal-footer {
        button {
            width: 100%;
            padding: 1rem;
            border: none;
            border-bottom-right-radius: 14px;
            border-bottom-left-radius: 14px;
            font-size: 16px;
            color: var(--green);
        }
    }
`;