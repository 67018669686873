import { requestConsentimento } from '../interfaces/consentimento';
import api, { consentId } from './api';

export const consentimento = {

    /**
     * @param autorizaRejeita
     * It must be AUTORIZADO | REJEITADO
     */
    atualizaConsentimento: (autorizaRejeita: string, dadosRequest: requestConsentimento) => {
        return api.patch(
          `/canais/v1/consentimento/${consentId}/status/${autorizaRejeita}`,
          dadosRequest
        );
    },

    buscaDadosTransacao: () => {
        return api.get("/canais/v1/consentimento/" + consentId);
    }
};