import { Content, SubContent } from './styles'

type Props = {
    children?: React.ReactNode;
};

export function Container({ children }: Props) {
    return (
        <Content>
            {children}
        </Content>
    )
}

export function SubContainer({ children }: Props) {
    return (
        <SubContent>
            {children}
        </SubContent>
    )
}