import styled from 'styled-components';

export const Icon = styled.img`
    position: absolute;

    &#bg-line-1 {
        top: 0;
        left: 0;
        display: none;
    }

    &#bg-line-2 {
        top: -34px;
        right: 0;
    }

    &#bg-line-3 {
        bottom: 0;
        right: 0;
        display: none;
    }

    @media (min-width: 1024px) {
        &#bg-line-1,
        &#bg-line-3 {
            display: block;
        }

        &#bg-line-2 {
            top: 0;
        }
    }
`;

export const Content = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    min-height: 100vh;
    height: 100%;
    z-index: 5;
`;