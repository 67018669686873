import styled from 'styled-components';

export const Info = styled.div`
    width: 100%;
    margin: 2rem 0;
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
        padding-left: 40px;
    }

    p {
        max-width: 216px;
        margin-left: 1rem;
        color: #828282;
    }
`;

export const Icon = styled.img`
    &.unicred {
        height: 22px;
    }

    &.iniciadora {
        max-width: 60%;
        max-height: 80px;
    }
`;
