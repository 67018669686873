import { ModalTypesInt } from "../../context/ModalContext";

const ModalTypes: ModalTypesInt = {
    "CANCEL": {
        title: "Tem certeza que deseja cancelar esta transação?",
        text: "Após cancelar, você irá retornar para a iniciadora de pagamentos.",
        confirmBtn: "Sim",
        cancelBtn: "Não",
        type: "CANCEL",
        showModal: true
    },
    "CANCEL-SHARE": {
        title: "Tem certeza que deseja recusar o compartilhamento?",
        text: "Você terá que realizar uma nova solicitação de compartilhamento.",
        confirmBtn: "Confirmar",
        cancelBtn: "Voltar",
        type: "CANCEL-SHARE",
        showModal: true
    },
    "FUNDS": {
        title: "Saldo insuficiente",
        text: "O saldo da conta é insuficiente para realizar este pagamento.",
        confirmBtn: "Ok, entendi",
        cancelBtn: "",
        type: "FUNDS",
        showModal: true
    },
    "INFO": {
        title: "Sobre o Open Finance",
        text: "O Open Finance é o sistema financeiro aberto, que permite novos tipos de transações financeiras, regulamentado pelo Banco Central. Após acessar sua conta, você dará o consentimento para que a transação que você iniciou seja autorizada. Tudo isso com a segurança e a privacidade da Unicred para os seus cooperados!",
        confirmBtn: "Fechar",
        cancelBtn: "",
        type: "INFO",
        showModal: true
    },
    "OVERDRAFT": {
        title: "Limite do cheque especial",
        text: "Para efetivar a transferência, será utilizado [R$0,00] de seu limite de cheque especial. Deseja prosseguir?",
        confirmBtn: "Sim",
        cancelBtn: "Voltar",
        type: "OVERDRAFT",
        showModal: true
    }
}

export default ModalTypes;