import styled from 'styled-components';

export const CTA = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 1.5rem;
`;

export const Description = styled.div`
    width: 100%;
    margin-bottom: 2rem;
    text-align: left;

    h2 {
        font-size: 22px;
        font-weight: 700;
        color: #3C3C3C;
        margin-bottom: 0.5rem;
    }

    p {
        font-size: 16px;
        color: #3C3C3C;
    }

    @media (min-width: 1024px) {
        h2 {
            color: #FFFFFF;
        }

        p {
            color: #FFFFFF;
        }
    }
`;

export const Info = styled.p`
    width: 100%;
    max-width: 370px;
    margin-top: 1.5rem;
    text-align: center;

    @media (min-width: 1024px) {
        max-width: 410px;
    }
`;

export const Transaction = styled.main`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 105%;

    & > div {
        width: 100%;
        max-width: 410px;
    }

    & > div + div {
        margin-top: 2rem;
    }

    @media (min-width: 1024px) {
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;

        & > div {
            max-width: 410px;
        }

        & > div + div {
            margin-top: 0;
            margin-left: 2rem;
        }
    }
    & .info-card {
        margin: 1rem 1rem 0 1rem;
        text-align:center;

        @media (max-width: 720px) {
            margin: 25px 5px 0 5px;
        }
    }
    & .info-multi {
        margin-left: 20px;

        @media (max-width: 720px) {
            margin-left: 0px;
        }
    }

    & .info-card {
        @media (min-width: 1024px) {
            padding: 15px 15px 0 15px;
            text-align: center;
        }
        padding-top:15px;
        text-align: center;
    }
    
`;