import styled from 'styled-components';

export const Content = styled.section`
    width: 100%;
    max-width: 410px;
    padding: 16px;
    border: 1px solid #FFFFFF;
    margin: 0 auto;
    background: #FFFFFF;
    border-radius: 0.625rem;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

    &.full {
        max-width: 100%;
    }

    .card-body > div + div {
        margin-top: 1rem;
    }

    .card-body-line > div + div,
    .card-body-double-col > div + div {
        padding-top: 1rem;
        border-top: 1px solid #E5E5D6;
        margin-top: 1rem;
    }

    .card-header {
        @media (min-width: 1024px) {
            padding: 0rem 1rem 1rem 2rem;
        }
        padding-bottom: 1rem;
        border-bottom: 1px solid #D9D9D9;
        margin-bottom: 1rem;
        color: #3C3C3C;
        text-align: center;

        h2 {
            font-size: 22px;
            margin-bottom: 0.5rem;
        }

        p {
            font-size: 16px;
            color: #191919;
        }
    }

    & h3 {
        font-size: 14px;
        margin-bottom: 0.25rem;
    }

    & .transaction-info {
        display: block;
        font-size: 16px;
        line-height: 24px;
        color: #004431;
        padding-right: 8px;
    }

    & .message-info {
        display: block;
        font-size: 13px;
        line-height: 24px;
        color: #828282;
    }

    & .info-side {
       
        width: 50%;
        float: left;  
        
        @media (max-width: 720px) {
            width: 100%;
            padding-bottom: 15px;
        }

    }

    & #value {
        display: block;
        margin: 0.5rem 0;
        font-size: 29px;
        line-height: 32px;
        color: #009168;
    }

    & #balance {
        display: block;
        font-size: 14px;
        line-height: 16px;
        color: #191919;
    }

    & .authorization-limit {
        font-size: 22px;
        font-weight: 700;
        color: var(--green);
        margin: 0.5rem 0;
       
    }
    & .message-info {
        color: #808080;
        font-size: 13px
        
    }

    @media (min-width: 1024px) {
        max-width: 410px;

        .card-body-double-col {
            display: flex;
            flex-wrap: wrap;
        }

        .card-body-double-col > div:first-child {
            width: 100%;
        }

        .card-body-double-col > div + div {
            width: 50%;
        }
    }
`;