import { CSSObject } from 'styled-components';
import { Content } from './styles'

type Props = {
    value?: string,
    onClick?: () => void,
    className?: string,
    style?:CSSObject,
    disabled?: boolean
};

export function Button({ className, value, onClick, style, disabled }: Props) {
    return (
        <Content className={className} onClick={onClick} style={style} disabled={disabled} > 
            {value} 
        </Content>
    )
}