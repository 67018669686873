import styled from 'styled-components';

export const CTA = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 1.5rem;
`;

export const ErrorArea = styled.section`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 370px;
    padding: 1rem;
    border-radius: 5px;
    margin-top: 1rem;
    background-color: #e8dbde;
    color: #DE1645;
`;

export const Icon = styled.img`
    width: 16px;
    height: 16px;
    margin-right: 1rem;
`;

export const Warning = styled.p`
    text-align: center;
    color: #4D4D4D;
    margin: 4rem 0 0.5rem;

    strong {
        text-transform: uppercase;
    }
`;
