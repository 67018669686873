import { Card } from '../../components/Card';
import { Container, SubContainer } from '../../components/Container';
import { Info, Icon } from './styles';
import logo from '../../assets/images/unicred-logo-green.svg';
import arrow from '../../assets/icons/ubr-arrow-down.svg';
import Title from '../../components/Title';
import { CSSObject } from 'styled-components';
import { useContext, useEffect, useState } from 'react';
import { ConsentimentoRequest } from '../../context/ConsentimentoContext';
import { useNavigate } from 'react-router-dom';
import { CompartilhamentoContext } from '../../context/CompartilhamentoContext';
import bankIcon from '../../assets/images/bank-icon.png';
import { AuthContext } from '../../context/AuthContext';

const Redirect = (): JSX.Element => {
  const history = useNavigate();
  const contextConsentimento = useContext(ConsentimentoRequest);
  const contextCompartilhamento = useContext(CompartilhamentoContext);
  const [logoSrc, setLogoSrc] = useState('');
  const authContext = useContext(AuthContext);

  const titleStyles: CSSObject = {
    fontSize: '20px',
    maxWidth: '430px',
    margin: '0 auto',
  };
  const cardStyles: CSSObject = {
    padding: '32px 0px',
  };
  const infoStyles: CSSObject = {
    paddingBottom: '20px',
    paddingTop: '20px',
    backgroundColor: '#e9e9e9',
  };
  const logosStyles: CSSObject = {
    display: 'block',
    padding: '0 1rem',
    margin: '0 auto',
  };

  const validaLogo = () => {
    var logo = contextCompartilhamento.isShare
      ? contextCompartilhamento.dados.instituicao.icone
      : contextConsentimento.iniciadora.urlLogoIniciadora;

    if (!!logo) {
      setLogoSrc(logo);
    } else {
      setLogoSrc(bankIcon);
    }
  };

  const redirect = () => {
    setTimeout(() => {
      try {
        const redirectUrl = contextConsentimento.iniciadora.urlRedirecionamento;
        if (redirectUrl) {
          window.location.href = redirectUrl;
        } else {
          history('/responses/redirect');
        }
      } catch (error) {
        console.error(error);
        history('/responses/redirect');
      }
    }, 5000);
  };

  useEffect(() => {
    if (!contextCompartilhamento.isShare && !contextConsentimento.hasData) {
      history('/');
    }

    validaLogo();
    redirect();
  }, []);

  return (
    <Container>
      <SubContainer>
        <Title title="Redirecionamento">
          <p style={titleStyles}>
            {contextCompartilhamento.isShare
              ? 'Você será redirecionado para a instituição receptora para os próximos passos.'
              : 'Você será redirecionado para a instituição iniciadora de pagamentos'}
          </p>
        </Title>

        <Card style={cardStyles}>
          <div className="card-wrapper">
            <div className="card-body">
              <Icon className="unicred" style={logosStyles} src={logo} alt="Logo Unicred" />

              <Info style={infoStyles}>
                <Icon src={arrow} />
                <p>
                  {contextCompartilhamento.isShare
                    ? 'Estamos lhe redirecionando de volta para o ambiente da Instituição receptora'
                    : 'Estamos lhe redirecionando para o ambiente da Instituição selecionada'}
                </p>
              </Info>

              {contextCompartilhamento.isShare ? (
                <Icon
                  className="iniciadora"
                  style={logosStyles}
                  src={logoSrc}
                  alt="Logo iniciadora"
                />
              ) : (
                <Icon
                  className="iniciadora"
                  style={logosStyles}
                  src={logoSrc}
                  alt="Logo iniciadora"
                />
              )}
            </div>
          </div>
        </Card>
      </SubContainer>
    </Container>
  );
};

export default Redirect;
