import { createContext, useEffect, useState } from 'react';
import Keycloak, { KeycloakConfig, KeycloakInitOptions } from 'keycloak-js';
import api, { consentId } from '../services/api';
import { useNavigate } from 'react-router-dom';

// ##############################
// Keycloak Setup
// ##############################
const keycloakConfig: KeycloakConfig = {
  realm: `${process.env.REACT_APP_KC_REALM}`,
  clientId: `${process.env.REACT_APP_KC_CLIENT}`,
  url: process.env.REACT_APP_AUTH_HOST,
};

const sessionToken = sessionStorage.getItem('access-token') || undefined;
const sessionRefreshToken = sessionStorage.getItem('refresh-token') || undefined;

const keycloakInitOptions: KeycloakInitOptions = {
  token: sessionToken,
  refreshToken: sessionRefreshToken,
};

const keycloak = Keycloak(keycloakConfig);

// http://localhost:3000/?intent_id=urn:unicred:9af782a1-1914-46b6-8a42-3d80e0594314

// ##############################
// Auth Context
// ##############################
interface AuthContextValues {
  hasStarted: boolean;
  isAuthenticated: boolean;
  logout: () => void;
  login: () => void;
}

const defaultAuthContextValues: AuthContextValues = {
  hasStarted: false,
  isAuthenticated: false,
  logout: () => {},
  login: () => {},
};

export const AuthContext = createContext<AuthContextValues>(defaultAuthContextValues);

// ##############################
// Provider
// ##############################
interface AuthContextProviderProps {
  children: JSX.Element;
}

const AuthContextProvider = (props: AuthContextProviderProps) => {
  const [hasStarted, setHasStarted] = useState<boolean>(false);
  const [isAuthenticated, setAuthenticated] = useState<boolean>(false);

  const logout = () => {
    console.info('Keycloak: logout');
    sessionStorage.removeItem('access-token');
    sessionStorage.removeItem('refresh-token');
    sessionStorage.removeItem('authorization');
    sessionStorage.removeItem('consentId');
    setAuthenticated(false);
    setHasStarted(false);

    keycloak.logout({ redirectUri: `${process.env.REACT_APP_HOMEPAGE}?intent_id=${consentId}` });
  };

  const login = () => {
    keycloak.login();
  };

  const updateHeaders = (token: string) => {
    console.info('API: updated headers');

    api.defaults.headers = {
      Authorization: `Bearer ${token}`,
      accept: 'application/json',
    };
  };

  const init = async () => {
    try {
      console.info('Keycloak: init');
      keycloak.init(keycloakInitOptions);

      keycloak.onAuthSuccess = () => {
        console.info('Keycloak: user authenticated');
        const { token = '', refreshToken = '' } = keycloak;
        sessionStorage.setItem('access-token', token);
        sessionStorage.setItem('refresh-token', refreshToken);
        sessionStorage.setItem('authorization', token);
        updateHeaders(token);
        setAuthenticated(true);
      };

      keycloak.onReady = () => {
        setHasStarted(true);
      };
    } catch {
      console.error('Keycloak: error initializing');
      logout();
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <AuthContext.Provider value={{ hasStarted, isAuthenticated, logout, login }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
