import { Container, SubContainer } from '../../components/Container';

import openFinanceLogo from '../../assets/images/openfinance-logo.svg'
import unicredLogo from '../../assets/images/unicred-logo.svg'

import { Logos, OpenBankingLogo, UnicredLogo } from './styles';

export function Header() {
    return (
        <Container>
            <SubContainer>
                <Logos>
                    <div>
                        <UnicredLogo src={unicredLogo} alt="Logo Unicred" />
                    </div>
                    <div>
                        <OpenBankingLogo src={openFinanceLogo} alt="Logo Open Finance" />
                    </div>
                </Logos>
            </SubContainer>
        </Container>
    );
};