import { CSSObject } from 'styled-components';
import { SubContainer } from '../../components/Container';
import { Description, Icon } from './styles';

type Props = {
    title: string;
    children?: React.ReactNode;
    icon?: string;
    iconStyle?: CSSObject;
};

export function Title({ title, children, icon, iconStyle }: Props) {
    return (
        <SubContainer>
            {!!icon ? <Icon src={icon} alt="Ícone" style={iconStyle} /> : ""}
            
            <Description>
                <h1>{title}</h1>

                {!!children ? children : ""}
            </Description>
        </SubContainer>
    );
};

export default Title;