interface AppRedirectOptions {
    iosApp: string;
    iosAppStore: string;
    android: {
      host: string;
      scheme: string;
      package: string;
      action?: string;
      category?: string;
      component?: string;
      fallback: string;
    };
    overallFallback: string;
  }
  
  const appDataMobile: AppRedirectOptions = {
    iosApp: "br.com.unicred.Unicred://",
    iosAppStore: "https://apps.apple.com/br/app/unicred-mobile/id955807456",
    android: {
      host: "openbanking.unicred.com.br",
      scheme: "https",
      package: "br.com.unicredmobile",
      fallback:
        "https://play.google.com/store/apps/details?id=br.com.unicredmobile",
    },
    overallFallback: "https://www.unicred.com.br/",
  };
  
  export const redirect = () => {
    let appData: AppRedirectOptions = appDataMobile;

    if (/iP(hone|ad|od)/.test(navigator.userAgent))
      window.location.href = appData.iosAppStore;
    else if (/Android/.test(navigator.userAgent)) {
      const intent = appData.android;
      const intentUrl =
        "intent://" +
        intent.host +
        "#Intent;" +
        "scheme=" +
        encodeURIComponent(intent.scheme) +
        ";" +
        "package=" +
        encodeURIComponent(intent.package) +
        ";" +
        "S.browser_fallback_url=" +
        encodeURIComponent(intent.fallback) +
        ";" +
        "end";
  
      //@ts-ignore
      window.location = intentUrl;
    } else window.location.href = appData.overallFallback;
  };