import styled from 'styled-components';

export const Content = styled.div`
    margin: 0 auto;
    padding: 0 1rem;
`;

export const SubContent = styled.div`
    width: 100%;
    max-width: 34rem;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-direction: column;

    @media (min-width: 1024px) {
        max-width: 865px;
    }
`;