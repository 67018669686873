import React, { ReactNode } from 'react';
import { ContextConsentimento } from '../interfaces/consentimento';

interface Props {
    children: ReactNode
}

let dadosConsentimento: ContextConsentimento = {
    duplaAssinatura: false,
    errorMessage: "",
    hasData: false,
    insufficientFunds: false,
    saldoLimite: 0,
    iniciadora: {
        urlLogoIniciadora: "",
        urlRedirecionamento: ""
    },
    request: {
        autenticacaoEletronica: "",
        canal: 1,
        codTransacao: 0,
        consentimento: {
            businessEntity: {
                document: {
                    identification: "",
                    rel: ""
                }
            },
            consentId: "",
            creationDateTime: "",
            creditor: {
                cpfCnpj: "",
                name: "",
                personType: ""
            },
            customerFriendlyLogoUri: "",
            debtorAccount: {
                accountType: "",
                ispb: "",
                issuer: "",
                number: ""
            },
            expirationDateTime: "",
            loggedUser: {
                document: {
                    identification: "",
                    rel: ""
                }
            },
            organisationId: "",
            organisationName: "",
            revocation: {
                loggedUser: {
                  document: {
                    identification: "",
                    rel: "",
                  }
                },
                revokedBy: "",
                reason: {
                  code: "",
                  additionalInformation: "",
                }
            },
            payment: {
                amount: "",
                currency: "",
                date: "",
                type: "",
                schedule:{
                    single:{
                        date: "",
                    }
                },
                details:{
                    localInstrument: "",
                    qrCode: "",
                    proxy: "",
                },
                creditorAccount: {
                    ispb: "",
                    issuer: "",
                    number: "",
                    accountType: "",
                  }
            },
            status: "",
            statusUpdateDateTime: ""
        },
        tokenSMS: "",
        unitoken: "",
        usarChequeEspecial: false
    }
};

export const ConsentimentoRequest = React.createContext(dadosConsentimento);

export const ConsentimentoProvider = ({ children }: Props) => {

    return (
        <ConsentimentoRequest.Provider value={dadosConsentimento}>
            {children}
        </ConsentimentoRequest.Provider>
    );
}