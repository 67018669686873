import styled from 'styled-components';


export const BusinessEntityArea = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-top: 1.5rem;

    & > img {
        width: 100%;
        max-width: 32px;
        height: 100%;
        max-height: 32px;
        margin-right: 1rem;
    }
`;

export const CardBottomExplanation = styled.p`
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    color: var(--gray-dark);
`;

export const CreditModalitiesArea = styled.div`
    & > p {
        margin: 1rem 0;
        font-size: 12px;
        color: #4D4D4D;
    }

    & ul {
        list-style: none;
        margin-bottom: 1rem;

        li {
            display: flex;
            justify-content: space-between;

            & + li {
                margin-top: 1.5rem;
            }
        }

        h4 {
            font-size: 14px;
            color: #191919;
        }

        span {
            font-size: 12px;
            color: #006650;

            &.none {
                color: #4D4D4D;
            }
        }

        button {
            border: none;
            background-color: inherit;
            font-size: 12px;
            color: var(--green);
            width: 20%;
        }
    }

    & .alert-msg {
        display: flex;
        align-items: center;
        padding: 1rem;
        border-radius: 8px;
        background: rgba(145, 15, 45, 0.16);

        img {
            width: 100%;
            max-width: 20px;
            height: 100%;
            max-height: 20px;
            margin-right: 1rem;
        }

        p {
            font-size: 14px;
            color: #191919;
        }
    }
`;

export const RequestedDataArea = styled.ul`
    list-style: none;

    & li {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0.8rem 0;

        &:first-child {
            padding-top: 1rem;
        }

        &:last-child {
            padding-bottom: 0;
        }

        & > img {
            width: 100%;
            max-width: 32px;
            height: 100%;
            max-height: 32px;
            margin-right: 1rem;
        }

        & > h4 {
            font-size: 14px;
            font-weight: bold;
            color: #191919;
            width: 66%;
        }

        & > button {
            border: none;
            background-color: inherit;
            font-size: 12px;
            color: var(--green);
            width: 20%;
        }
    }
`;

export const ModalInfoContent = styled.div`
    text-align: left;

    ul {
        padding-left: 1.2rem;
        margin-bottom: 2rem;
        color: #4D4D4D;

        li + li {
            margin-top: 0.5rem;
        }
    }

    & > p {
        margin: 1.5rem 0;
        font-size: 16px !important;
    }

    p:first-of-type{
        margin-top: 0;
    }

    & > a {
        font-size: 16px;
        font-weight: bold;
        text-decoration: none;
        color: var(--green);
    }
`;

export const ModalSelectContent = styled.div`

    hr {
        height: 1px;
        border: none;
        margin: 1rem 0;
        background: #000000;
        opacity: 0.1;
    }

    ul {
        margin-bottom: 1.5rem;
    }

    .custom-check {
        display: flex;
        justify-content: space-between;

        & + .custom-check {
            margin-top: 1rem;
        }

        span {
            font-size: 14px;
            color: #191919;
        }

        label {
            display: block;
            position: relative;
            padding-left: 20px;
            margin-bottom: 12px;
            cursor: pointer;
            font-size: 22px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
    
            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
    
                &:checked ~ .checkmark {
                    background-color: var(--green);
                    border-color: var(--green);
                }
    
                &:checked ~ .checkmark:after {
                    display: block;
                }
            }
    
            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 18px;
                width: 18px;
                border: 2px solid var(--gray-dark);
                border-radius: 2px;
    
                &:after {
                    content: "";
                    position: absolute;
                    display: none;
                    left: 4px;
                    top: 1px;
                    width: 4px;
                    height: 8px;
                    border: solid white;
                    border-width: 0 2px 2px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }
    
            &:hover input ~ .checkmark {
                background-color: var(--green);
                border-color: var(--green);
            }
        }
    }
`;