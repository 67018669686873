import { BrowserRouter } from 'react-router-dom';
import Rotas from './routes';
import { Providers } from './components/Providers';

const App = (): JSX.Element => {

  return (
    <BrowserRouter>
      <Providers>
        <Rotas />
      </Providers>
    </BrowserRouter>
  );
};

export default App;