import styled from 'styled-components';

export const MessagesWrap = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;

  & img {
    margin-bottom: 1rem;
  }

  & p {
    max-width: 400px;
    margin: 0 auto;
    white-space: pre-wrap;

    &.return {
      max-width: 350px;
      margin: 1rem auto 0;
      font-weight: bold;
    }
  }

  & button:first-of-type {
    margin-top: 4.5rem;
  }
`;
