import React, { ReactChild, useState } from 'react';
import ModalTypes from '../components/Modal/ModalTypes';

interface Props {
    children: ReactChild
}

interface ModalContent {
    title: string,
    text: string,
    confirmBtn: string,
    cancelBtn: string,
    type: string,
    showModal: boolean,
}

export interface ModalTypesInt {
    [key: string]: ModalContent
}

let modalData: ModalContent = {
    title: "",
    text: "",
    confirmBtn: "",
    cancelBtn: "",
    type: "",
    showModal: false,
}

export const ModalContext = React.createContext({
    data: modalData,
    update: (type: string, data?: ModalContent) => {}
});

export const ModalProvider = ({ children }: Props) => {
    const [data, setData] = useState(modalData);
    const modalTypes: ModalTypesInt = ModalTypes;

    let providerValue = {
        data,
        update: (type: string, newData?: ModalContent) => {
            let modalType = modalTypes[type];

            if (newData) {
                let customData: ModalContent;
                if (!!modalType) {
                    customData = {
                        ...modalType,
                        ...newData
                    }
                } else {
                    customData = newData;
                }

                setData(() => customData);
            } else {
                if (!!modalType) {
                    setData(() => modalType);
                } else {
                    setData(() => modalData);
                }
            }
        }
    }

    return (
        <ModalContext.Provider value={providerValue}>
            {children}
        </ModalContext.Provider>
    );
}