import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { Container, SubContainer } from '../../components/Container';

const Home = (): JSX.Element => {
  const history = useNavigate();
  const authContext = useContext(AuthContext);
  const [disableLogin, setDisableLogin] = useState(true);

  const location = useLocation()

  const login = async () => {
    if (/Android/i.test(navigator.userAgent)) {
      window.location.href = `unicredMobile://openfinance${location.search}`;
    }

    setTimeout(function() {
      authContext.login();
    }, 100);
  };

  useEffect(() => {
    if (authContext.hasStarted) {
      if (authContext.isAuthenticated) {
        setDisableLogin(true);
        history('/carregando');
      } else {
        setDisableLogin(false);
      }
    }
  }, [authContext]);

  return (
    <Container>
      <SubContainer>{!disableLogin && login()}</SubContainer>
    </Container>
  );
};

export default Home;