import styled from 'styled-components';

export const Content = styled.section`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.5);
    z-index: 1000;

    .modal-wrapper {
        width: 330px;
        border-radius: 10px;
        background-color: #FFFFFF;
        text-align: center;
    }

    .modal-header {
        padding: 2rem 1rem 0 0;

        h2 {
            font-size: 18px;
            font-weight: 700;
            color: #333333;
        }
    }

    .modal-content {
        overflow-x: auto;
        max-height: 55vh;
        padding: 0 1rem 1rem;
        margin-top: 1rem;

        p {
            font-size: 14px;
            color: #4D4D4D;
        }
    }

    .modal-footer {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        button {
            min-width: 100px;
            width: 100%;
            font-size: 16px;
        }

        .button-close {
            width: 100%;
            padding: 1rem;
            border-width: 1px 0 0 0;
            border-color: #D9D9D9;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
            font-size: 16px;
            color: var(--green);
        }

        &.dialog button {
            width: 50%;
            padding: 0.8rem;
            margin: 1rem;
        }
        
        &.dialog button + button {
            width: 50%;
            border-color: var(--green);
            margin-left: 0;
        }
     }
`;