import { useContext, useEffect, useState } from "react";
import { CompartilhamentoContext } from "../../../context/CompartilhamentoContext";
import { ModalInfoContent } from "./styles";


interface Props {
    title: string
}

type listType = {
    descricao: string,
    itens: string[],
    informacoesAdicionais?: {
        titulo: string,
        dados: string[]
    }
}


const Information = ({ title }: Props): JSX.Element => {
    const shareContext = useContext(CompartilhamentoContext);
    const [list, setList] = useState<listType>({
        descricao: '',
        itens: [''],
        informacoesAdicionais: undefined
    });


    useEffect(() => {
        let auxList: listType = { descricao: '', itens: [], informacoesAdicionais: undefined };

        const dados = shareContext.dados.dadosCompartilhamento.dadosSolicitados;

        for (let i = 0; i < dados.length; i++) {
            if (title === dados[i].nome) {
                auxList.descricao = dados[i].descricao;
                auxList.itens.push(...dados[i].dados);

                if (dados[i].informacoesAdicionais) {
                    auxList.informacoesAdicionais = dados[i].informacoesAdicionais;
                }
            }

        }

        setList(auxList);
    }, [title]);

    return (
        <ModalInfoContent> 
            <p> {list.descricao} </p>
            <ul>
                {list.itens.map((item, itemKey) => (
                    <li key={itemKey}>{item}</li>
                ))}
            </ul>

            { !!list.informacoesAdicionais && (
                <>
                    <p>{list.informacoesAdicionais.titulo}</p>
                    <ul>
                        {list.informacoesAdicionais.dados.map((item, itemKey) => (
                            <li key={itemKey}>{item}</li>
                        ))}
                    </ul>
                </>
            )}

            <p>Para maiores informações, consulte o Portal do Cidadão:</p>

            <a href="https://openbankingbrasil.org.br/escopo-de-dados-dicionario/" target="_blank" rel="noreferrer">
                Acessar Portal do Cidadão
            </a>
        </ModalInfoContent>
    );
}

export default Information;