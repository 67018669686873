import React, { useEffect } from 'react';
import { Container, SubContainer } from '../../components/Container';
import { Button } from '../../components/Button';
import Title from '../../components/Title';
import { CallbackWrapper } from './styles';
import { redirect } from '../../common/redirect';
import { useLocation } from 'react-router-dom';

const Callback = (): JSX.Element => {

  const location = useLocation()

  useEffect(() => {
    const timeout = setTimeout(() => {
      redirectMobile();
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const redirectMobile =  () => {
    if (/Android/i.test(navigator.userAgent)) {
      window.location.href = `unicredMobile://openfinance${location.pathname}${location.hash}`;
    }

    setTimeout(function() {
      redirect();
    }, 100);
  }

  return (
    <Container>
      <SubContainer>
        <CallbackWrapper>
          <Title title="Redirecionamento">
            <p>Sua solicitação está sendo processada, continue pelo aplicativo Unicred Mobile pelo botão a seguir.</p>
          </Title>
          <Button
            className="full"
            value="Continuar no app Unicred Mobile"
            onClick={redirectMobile}
          />
        </CallbackWrapper>
      </SubContainer>
    </Container>
  );
};

export default Callback;
