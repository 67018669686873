import axios from 'axios';
import helper from '../common/Helpers';

// URL base open-banking-bff
// Proxy realizado no kubernetes, pós build, implementada no dockerfile
const api = axios.create({
  baseURL: `${process.env.REACT_APP_BFF}`
});

// Get consentId from url path
const consentIdValue = helper.getUrlParam('intent_id');
const consentIdSession = sessionStorage.getItem('consentId');
export let consentId = '';

if (!!consentIdValue) {
  consentId = consentIdValue;
  sessionStorage.setItem('consentId', consentId);
} else if (!!consentIdSession) {
  consentId = consentIdSession;
}

export default api;
