import { PayloadConfirmar } from '../interfaces/compartilhamento';
import api, { consentId } from './api';

export const compartilhamento = {
    
    /**
     * @param action 
     * It must be AUTORIZADO | ENCERRADO | DETALHADO | REJEITADO
     */
    atualizaCompartilhamento: (shareId: string, action: string) => {
        return api.patch(`/canais/v1/compartilhamentos/${shareId}/status/${action}`);
    },

    buscaDetalhes: () => {        
        return api.get(`/canais/v1/compartilhamentos/consentimentos/${consentId}`);
    },

    confirmaCompartilhamento: (payload: PayloadConfirmar) => {
        return api.post("/canais/v1/compartilhamentos", payload);
    }
};