import api, { consentId } from './api';

export const consentimentos = {
    rejeitaConsentimentos: () => {
        return api.post(`/openfinance/v1/consentimentos/${consentId}/rejected?toggle=false`);
    },

    buscaDadosConsentimentos: () => {
        return api.get(`/openfinance/v1/consentimentos/${consentId}`);
    },
};