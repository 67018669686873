import { useContext, useEffect, useState } from "react";
import { firstLetterCapitalized } from "../../../common/Helpers";
import { CompartilhamentoContext } from "../../../context/CompartilhamentoContext";
import { ModalContext } from "../../../context/ModalContext";
import { ModalSelectContent } from "./styles"


interface Props {
    product: string
}

const SelectInfo = ({ product }: Props): JSX.Element => {
    let { creditModalitites, selectModalProduct } = useContext(CompartilhamentoContext);
    const products = creditModalitites.data;
    const modalContext = useContext(ModalContext);
    const [allSelected, setAllSelected] = useState(false);
    const [modalities, setModalities] = useState([{
        codigo: 0,
        descricao: "",
        nomeSucinto: "",
        selected: false
    }]);

    const updateModal = () => {
        modalContext.update("", {
            title: selectModalProduct,
            text: "",
            confirmBtn: "Ok",
            cancelBtn: "",
            type: "SELECT-SHARE",
            showModal: true
        });
    }

    /**
     * Verifica se todos os checkboxes de um produto estão selecionados
     * para alterar o botão "Selecionar tudo"
     */
    const checkAllSelected = () => {
        let auxAllSelected = true;

        for (let i = 0; i < products.length; i++) {
            const currentProduct = products[i];

            if (currentProduct.nome === product) {

                for (let j = 0; j < currentProduct.modalidade.length; j++) {
                    const currentModality = currentProduct.modalidade[j];

                    if (currentModality.selected !== true) {
                        auxAllSelected = false;
                    }
                }

            }
        }

        setAllSelected(auxAllSelected);
    }

    /**
     * Verifica todos os produtos e modalidades se há algum
     * selecionado pra apresentar ou não as mensagens de aviso
     */
    const checkHasSelected = () => {
        let hasChecked = false;

        for (let i = 0; i < products.length; i++) {
            const currentProduct = products[i];

            for (let j = 0; j < currentProduct.modalidade.length; j++) {
                const currentModality = currentProduct.modalidade[j];

                if (currentModality.selected) {
                    hasChecked = true;
                    break;
                }
            }
        }

        creditModalitites.hasSelected = hasChecked;
    }

    /**
     * Marca ou desmarca todos os checkboxes de um produto
     * @param event Evento de marcar/desmarcar checkbox
     */
    const selectAll = (event: any) => {
        const checked = event.target.checked;

        for (let i = 0; i < products.length; i++) {
            const currentProduct = products[i];

            if (currentProduct.nome === product) {
                let amountItems = 0;

                for (let j = 0; j < currentProduct.modalidade.length; j++) {
                    const currentModality = currentProduct.modalidade[j];

                    if (currentModality.selected !== checked) {
                        currentModality.selected = checked;
                        amountItems++;
                    }
                }

                currentProduct.numSelected = checked ? (currentProduct.numSelected + amountItems) : 0;
            }
        }

        updateList();

        if (checked) {
            creditModalitites.hasSelected = true;
        } else {
            checkHasSelected();
        }
    }

    /**
     * Marca ou desmarca uma checkbox
     * @param event Evento de marcar/desmarcar checkbix
     * @param codigo Código da modalidade selecionada
     */
    const select = (event: any, codigo: number) => {
        const checked = event.target.checked;

        for (let i = 0; i < products.length; i++) {
            const currentProduct = products[i];

            if (currentProduct.nome === product) {

                for (let j = 0; j < currentProduct.modalidade.length; j++) {
                    const currentModality = currentProduct.modalidade[j];

                    if (currentModality.codigo === codigo) {

                        currentModality.selected = checked;

                        if (checked) {
                            creditModalitites.hasSelected = true;
                            currentProduct.numSelected++;
                        } else {
                            currentProduct.numSelected--;
                            setAllSelected(false);
                            checkHasSelected();
                        }

                        break;
                    }
                }

                break;
            }
        }

        updateList();
    }

    /**
     * Atualiza o state da lista de modalidades para que haja
     * a alteração visual dos componentes
     */
    const updateList = () => {
        for (let i = 0; i < products.length; i++) {
            const currentProduct = products[i];

            if (currentProduct.nome === product) {
                checkAllSelected();
                setModalities(currentProduct.modalidade);
                updateModal();

                break;
            }
        }
    }

    useEffect(() => {
        updateList();
    }, [product, creditModalitites]);

    return (
        <ModalSelectContent>
            <div className="custom-check">
                <span>Selecionar tudo</span>

                <label>
                    <input data-testid="selecionar_todos" type="checkbox" checked={allSelected} onChange={event => {selectAll(event)}} />
                    <span className="checkmark"></span>
                </label>
            </div>

            <hr />

            <ul>
                {modalities.map((modality, key) => (
                    <li className="custom-check" key={key}>
                        <span>{firstLetterCapitalized(modality.nomeSucinto)}</span>

                        <label>
                            <input data-testid="selecionar_item" type="checkbox" checked={modality.selected} onChange={event => {select(event, modality.codigo)}} />
                            <span className="checkmark"></span>
                        </label>
                    </li>
                ))}
            </ul>
        </ModalSelectContent>
    );
}

export default SelectInfo;