import React, { ReactNode } from 'react';
import { ContextCompartilhamento } from '../interfaces/compartilhamento';

interface Props {
    children: ReactNode
}

let dadosCompartilhamento: ContextCompartilhamento = {
    creditModalitites: {
        hasSelected: false,
        data: [
            {
                modalidade: [
                    {
                        codigo: 0,
                        descricao: "",
                        nomeSucinto: "",
                        selected: false
                    }
                ],
                nome: "",
                numSelected: 0
            }
        ]
    },
    hasData: false,
    infoModalName: "",
    selectModalProduct: "",
    isShare: false,
    dados: {
        cpfCnpj: "",
        dadosCompartilhamento: {
            dadosSolicitados: [
                {
                    dados: [],
                    descricao: "",
                    nome: "",
                    nomeCategoria: "",
                    informacoesAdicionais: {
                        titulo: "",
                        dados: []
                    }
                }
            ],
            data: "",
            dataExpiracaoCompartilhamento: "",
            prazo: ""
        },
        dataExpiracao: "",
        duplaAssinatura: false,
        id: "",
        instituicao: {
            cnpj: "",
            icone: "",
            nome: ""
        },
        mensagem: {
            descricao: "",
            titulo: ""
        },
        nome: "",
        produtos: [
            {
                modalidade: [
                    {
                        codigo: 0,
                        descricao: "",
                        nomeSucinto: ""
                    }
                ],
                nome: ""
            }
        ],
        quantidadeUsuariosConta: 0,
        status: "",
        textoOperacaoCreditoInvestimento: "",
    },
    payload: {
        autenticacaoEletronica: "",
        compartilhamentoId: "",
        consentimentoId: "",
        produtos: [
            {
                modalidades: [
                    {
                        codigo: 0,
                        descricao: ""
                    }
                ],
                nome: ""
            }
        ],
        teclas: [],
        tokenSMS: "",
        unitoken: ""
    }
};

export const CompartilhamentoContext = React.createContext(dadosCompartilhamento);

export const CompartilhamentoProvider = ({ children }: Props) => {

    return (
        <CompartilhamentoContext.Provider value={dadosCompartilhamento}>
            {children}
        </CompartilhamentoContext.Provider>
    );
}