import { PayloadAssinatura } from '../interfaces/compartilhamento';
import { geraQRCodeData } from '../interfaces/seguranca';
import api from './api';

export const seguranca = {

    dispositivoSeguranca: async () => {
        return api.get("/canais/v1/seguranca/dispositivo-seguranca");
    },

    enviarSMS: async () => {
        return api.post("/canais/v1/seguranca/enviar-token-sms");
    },

    gerarQRCode: async (token: string, dadosRequest: geraQRCodeData) => {
        return api.post("/canais/v1/unitoken-transparente/qr-code", dadosRequest, {
            headers: {
                "tokenQRCode": token
            }
        });
    },

    assinaturaEletronica: async (payload: PayloadAssinatura) => {
        return api.post("/canais/v1/seguranca/dispositivo-seguranca/assinatura-eletronica/validar", payload)
    },
};