import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
  :root {
      --green: #166649;
      --gray-light: #F2F2F2;
      --gray-dark: #808080;
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html {
    @media (max-width: 1080px) {
      font-size: 93.75%;
    }
    @media (max-width: 720px) {
      font-size: 87.5%;
    }
  }

  html, body {
    max-width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;
  }

  body, input, button {
    font: 16px "PT Sans", sans-serif;
  }

  body {
    position: relative;
    background-color: var(--gray-light);
  }

  body::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: -25%;
    left: -25%;
    height: 440px;
    background-color: var(--green);
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    z-index: 1;
  }

  @media (min-width: 1024px) {
    body::before {
      right: -25%;
      left: -25%;
    }
  }
  
  #root {
    margin: 0 auto;
    padding: 0 1rem;
    position: relative;
    z-index: 10;
  }

  #root > *:not(#background-lines):not(#modal) {
    position: relative;
    z-index: 20;
  }

  .hide {
    display: none !important;
  }

  .left {
    text-align: left;
  }
  .center {
    text-align: center;
    p {
      @media (min-width: 1024px) {
        padding: 1px 16px;
      }
    }
  }
  .right {
    text-align: right;
  }

  main {
    width: 100%;
  }

  /* ========================== */
  /* Form Styles */
  /* ========================== */
  input {
    width: 100%;
    max-width: 370px;
    padding: 0.75rem;
    border-radius: 5px;
    border: 1px solid var(--gray-light);
    color: var(--gray-dark);
    font-size: 16px;
  }

  @media (min-width: 1024px) {
    input {
      max-width: 410px;
    }
  }

  button {
    cursor: pointer;
  }

  [disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`;